@import '../../../App.css';
@import '../../../_variables.scss';

.SocialsSwiper {
  margin: rem(140) 0;
  background-color: var(--white-F6F6F6);
  $s: &;
  min-height: rem(750);

  &__swiper {
    margin-top: 24px;
  }

  &__slider {
    padding: 0 rem(112);
    position: relative;

    & > * {
      overflow: visible;
    }
  }

  &__header {
    width: 52%;
    font-family: var(--font-unbounded);
    font-size: rem(40);
    line-height: rem(48);
    padding: 0 rem(112);

    span {
      color: var(--blue-75BBFD);
    }
  }

  &__item {
    display: block;
    padding: rem(32);
    box-shadow: 0px 0px 26.75px 0px #00000008;
    border-radius: rem(21.4);
    font-family: var(--font-unbounded);
    font-size: rem(20);
    transition: all 0.6s ease;

    &:hover {
      #{$s}__button {
        background-color: var(--dark-blue-389FFF);
      }
    }
  }

  &__picture {
    margin-bottom: rem(32);
    height: rem(420);
    transition: all 0.6s ease;
    border-radius: rem(32.11);
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h3,
    span {
      font-size: 20px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-raleway);
    background-color: var(--blue-75BBFD);
    border-radius: rem(33.5);
    height: rem(31);
    padding: rem(9) rem(22);
    font-size: rem(14);
    color: var(--white-F6F6F6);
    transition: 0.6s;
  }

  @media all and (max-width: $XXL) {
    &__picture {
      height: auto;
    }
  }

  @media all and (max-width: $XL) {
    &__slider {
      padding: 0 rem(64);
      position: relative;

      &::before,
      &::after {
        width: rem(112);
        position: absolute;
        content: '';
        background-color: var(--white-F6F6F6);
        height: 100%;
        z-index: 10;
        content: '';
      }

      &::before {
        top: 0;
        left: -4rem;
      }

      &::after {
        top: 0;
        right: -4rem;
      }
    }

    &__header {
      width: 70%;
      padding: 0 rem(64);
      font-size: rem(32);
      line-height: rem(40);
    }

    &__item {
      padding: rem(24);
      box-shadow: 0px 0px rem(20) 0px #00000008;
      border-radius: rem(17.5);
    }

    &__picture {
      height: rem(315);
      border-radius: rem(17.5);
    }

    &__active {
      #{$s}__item {
        padding: rem(26) rem(18);
      }

      #{$s}__button {
        height: rem(34);
      }

      #{$s}__picture {
        margin-bottom: rem(34);
        height: rem(335);
      }
    }

    &__button {
      font-size: rem(11);
    }
  }

  @media all and (max-width: $L) {
    min-height: auto;
    // background-color: red;

    &__item img {
      min-height: rem(258);
    }

    // &__slider {
    //   &::before,
    //   &::after {
    //     content: none;
    //   }
    // }

    &__item,
    &__active &__item {
      padding: rem(16);
    }

    &__button,
    &__active &__button {
      height: rem(31);
    }

    &__picture,
    &__active &__picture {
      margin-bottom: rem(24);
      height: rem(258);
    }
  }

  // Адаптация под tablet
  @media all and (max-width: $M) {
    margin: rem(120) 0;

    &__swiper {
      margin-top: 0;
    }

    &__slider {
      &::before,
      &::after {
        content: none;
      }

      padding: 0;
      transform: translateX(rem(173));
    }

    &__header {
      width: 75%;
      font-size: rem(24);
      line-height: rem(32);
      padding: 0 0 rem(48) rem(173);

      span {
        color: var(--blue-75BBFD);
      }
    }

    &__bottom {
      h3,
      span {
        font-size: 16px;
      }
    }
  }

  // Адаптация под mobile
  @media all and (max-width: $S) {
    margin: rem(110) 0;

    &__item img {
      min-height: 0;
    }

    &__slider {
      transform: none;
    }

    &__header {
      width: 100%;
      font-size: rem(20);
      line-height: rem(28);
      padding: 0 rem(16) rem(48) rem(16);
    }

    &__bottom {
      h3,
      span {
        font-size: 16px;
      }
    }
  }

  @media all and (max-width: $XS) {
    &__slider {
      padding: 0;
      margin: 0 5px;
    }

    &__item {
      margin: 0 8px;
      border-radius: 12.42px;
    }

    &__picture,
    &__active &__picture {
      height: auto;
    }

    &__button {
      font-size: rem(10);
      padding: rem(8) rem(20);
    }
  }
}
