@import '../../../App.css';
@import '../../../_variables.scss';

.BenefitsGrid {
  padding: rem(120) 0;
  background: linear-gradient(
    261.46deg,
    rgba(255, 203, 193, 0.09) -4.67%,
    rgba(117, 187, 253, 0.09) 44.63%,
    rgba(188, 188, 188, 0.09) 103.07%
  );
  position: relative;

  &__star {
    position: absolute;
    z-index: -1;
    background-size: cover;

    &_1 {
      width: rem(485);
      height: rem(485);
      top: 20%;
      right: -10%;
    }

    &_2 {
      width: rem(485);
      height: rem(485);
      top: 0%;
      left: -7%;
    }

    &_3 {
      width: rem(214);
      height: rem(214);
      top: 5%;
      right: 10%;
    }

    &_4 {
      width: rem(222);
      height: rem(222);
      left: 3%;
      bottom: 1%;
    }
  }

  &__title {
    font-size: rem(32);
    font-family: var(--font-unbounded);
    width: 63%;
    margin: 0 auto rem(80) auto;
  }

  &__items {
    width: 63%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: rem(43);
    row-gap: rem(64);
  }

  &__item {
    color: var(--black-1D2E43);
    font-size: rem(16);
    line-height: rem(24);
  }

  &__icon {
    flex-shrink: 0;
    width: rem(160);
    margin-bottom: rem(16);

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__item {
    h3 {
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(24);
      margin-bottom: rem(8);
    }
  }

  @media all and (max-width: $XXL) {
    &__star {
      &_1 {
        width: rem(290);
        height: rem(290);
        top: 20%;
        right: -6%;
      }

      &_2 {
        width: rem(290);
        height: rem(290);
        top: 0%;
        left: -7%;
      }

      &_3 {
        width: rem(128);
        height: rem(128);
        top: 5%;
        right: 10%;
      }

      &_4 {
        width: rem(135);
        height: rem(135);
        left: 3%;
        bottom: 1%;
      }
    }

    &__title {
      width: 80%;
      margin: 0 auto rem(80) auto;
    }

    &__items {
      width: 80%;
      column-gap: rem(44);
      row-gap: rem(40);
    }
  }
  // Адаптация под tablet
  @media all and (max-width: $L) {
    padding: rem(80) 0;

    &__star {
      display: none;
    }

    &__title {
      width: 66%;
      font-size: rem(24);
      margin-bottom: rem(54);
    }

    &__items {
      width: 66%;
      grid-template-columns: repeat(2, 1fr);
      column-gap: rem(100);
    }
  }

  // Адаптация под mobile
  @media all and (max-width: $S) {
    padding: rem(64) rem(16);

    &__title {
      margin-bottom: rem(48);
      width: 100%;
    }

    &__items {
      width: 100%;
      grid-template-columns: 1fr;
      gap: rem(48);
    }

    &__item {
      display: flex;
      align-items: center;
    }

    &__icon {
      width: rem(80);
      margin-bottom: 0;
      margin-right: rem(8);
    }
  }
}
