@import '../../App.css';

.containerImageToDescription {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  &__img {
    object-position: center;
    width: 100%;
    height: 100%;
    max-height: 50vw;
    border-radius: 16px;
    object-fit: cover;
  }

  &__text {
    color: var(--gray-959DA6);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 1.25rem;
    padding-top: 0.5rem;
    word-wrap: break-word;
  }
}

// ваыаываы

.blockHeadingTitle {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2.5rem 0;

  &__heading {
    color: var(--black-1D2E43);
    font-family: var(--font-unbounded);
    font-size: 24px;
    font-style: normal;
    font-weight: var(--fw-300);
    line-height: 32px;
    word-wrap: break-word;
  }

  &__text {
    color: var(--black-1D2E43);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 24px;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

// <<<< =========== стили для блока у которого 2 горизонтальные картинки и 1 вертикальная картинка imageBlockThree =========== >>>>

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /* Здесь используем fr для автоматического распределения пространства */
  gap: 30px;
  height: 100%;
  min-height: 50vh;
  align-items: stretch;
  margin-bottom: 90px;
}

.horizontalImage,
.verticalImage {
  width: 100%;
  /* Для корректного отображения изображений внутри блока */
  object-fit: cover;
  /* Изображение будет подгоняться так, чтобы заполнить свой блок без искажений */
  display: block;
  /* Убираем стандартные отступы для изображений */
}

.horizontalImage {
  grid-column: 1 / 2;
  min-height: 50vh;
  border-radius: 1rem;
  // object-fit: contain;
  /* 50% высоты экрана */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.horizontalImage:nth-child(1) {
  grid-row: 1 / 2;
}

.horizontalImage:nth-child(2) {
  grid-row: 2 / 3;
}

.verticalImage {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  // height: 100vh;
  border-radius: 1rem;
  object-fit: cover;
  /* 100% высоты экрана */
}

// <<< ====== стили для блока с двумя вертикальными картинками ====== >>>
.imageBlockTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  // height: 100vh;
  margin-bottom: 2.5rem;

  &__gridImage {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }
}

// <<< ====== стили для слайдера с картинками ====== >>>

// <<< ====== стили для блока с рамкой ====== >>>
.text_framed {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  gap: 10px;
  overflow: auto;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  &__text {
    color: var(--black-1D2E43);
    font-variant-numeric: lining-nums proportional-nums;
    /* Body Regular · 16 */
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 24px;
    word-wrap: break-word;
    border-radius: 16px;
    padding: 24px;
    border: 1px solid var(--salmon-FF7F6A);
    white-space: pre-wrap;
    word-break: break-word;
  }
}

// <<< ====== стили для блока заметкой  ====== >>>

.note_block {
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  word-wrap: break-word;

  &__bookmark {
    width: 9px;
    height: auto;
    flex-shrink: 0;
    background: var(--salmon-FF7F6A);
  }

  &__text {
    color: var(--black-1D2E43);
    text-align: start;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--fw-700);
    line-height: 24px;
    padding: 10px 0px;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

// <<< ====== стили для блока c текстом  ====== >>>
.text_block {
  color: var(--black-1D2E43);
  font-family: var(--font-raleway);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: 24px;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

// <<< ====== стили для блока c цитатой и автором  ====== >>>

.quote_block_constainer {
  position: relative;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  word-wrap: break-word;

  &__text_block {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    background-color: var(--white-F6F6F6);
  }

  &__quote_text {
    color: var(--black-1D2E43);
    font-variant-numeric: lining-nums proportional-nums;

    /* Body Regular · 16 */
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 24px;
    white-space: pre-wrap;
    word-break: break-word;
    /* 150% */
  }

  &__quote_author {
    color: var(--gray-959DA6);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 14px;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 20px;
  }

  &__author_block {
    word-wrap: break-word;
    position: absolute;
    right: 0;
    top: 0;
    right: 0;
    // top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__img {
    border-radius: 50%;
    width: 11.06rem;
    height: 11.06rem;
    object-fit: cover;
    object-position: center;
  }

  &__quote {
    color: var(--dark-gray-566272);
    font-family: var(--font-raleway);
    font-size: 12px;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 16px;
    padding-top: 10px;
    max-width: 10rem;
  }

  &__author {
    color: var(--gray-959DA6);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 12px;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 16px;
    word-wrap: break-word;
  }
}

.slider_container {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.wrapper_conteiner__item {
  display: flex;
}

.div_conteiner__blockSix {
  width: 100%;
  max-width: 100%;

  margin-bottom: 2.5rem;
}

.block_video {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 40vw;
  border-radius: 16px;
}

.buttonWrapperBlock {
  position: relative;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonPlayBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.225rem;
  height: 7.225rem;
  border-radius: 7.225rem;
  background: var(--, linear-gradient(135deg, rgba(187, 222, 255, 0.55) 3.43%, rgba(255, 191, 181, 0.55) 177.6%));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonPause {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.225rem;
  height: 7.225rem;
  border-radius: 7.225rem;
  background: var(--, linear-gradient(135deg, rgba(187, 222, 255, 0.55) 3.43%, rgba(255, 191, 181, 0.55) 177.6%));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonWrapperBlock1 {
  position: absolute;
  bottom: 18%;
  right: 3%;
}

.buttonFullscreen {
  display: flex;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.buttonExitFullscreen {
  display: flex;
  width: 4rem;
  height: 4rem;
  bottom: 18%;
}

.buttonFullscreen img,
.buttonExitFullscreen img {
  width: 100%;
  height: 100%;
}

.videoLinkContainer {
  width: 100%;
  height: 100%;
  margin-bottom: 2.5rem;
}

.block_videoLink {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 16px;
}

.quote_block_constainer__author_block {
  right: calc(-7.8vw - 16.56rem);
}

@media screen and (max-width: 1920px) {
  .quote_block_constainer__author_block {
    right: calc(-7.8vw - 8.56rem);
  }
  .text_framed {
    margin-bottom: 2.5rem;
  }
}

/* Стили для desctop версии +1 сайта блока ProjectCard начинаются тут */
@media screen and (min-width: 1025px) and (max-width: 1600px) {
  .blockHeadingTitle {
    padding-right: 0;
    padding-left: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }
  .containerImageToDescription {
    padding-left: 0;
  }

  .text_framed {
    padding-right: 0;
    padding-left: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .note_block {
    padding-right: 0;
    padding-left: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .text_block {
    padding-right: 0;
    padding-left: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .quote_block_constainer {
    padding-right: 0;
    padding-left: 0;
  }
  .imageBlockTwo {
    padding-left: 0;
  }
  .slider_container {
    padding-left: 0;
  }

  .div_conteiner__blockSix {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    margin-bottom: 2.5rem;
  }

  .buttonWrapperBlock1 {
    position: absolute;
    bottom: 20%;
    right: 3%;
  }

  .videoLinkContainer {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 2.5rem;
  }
}

/* Стили для планшетной версии сайта блока ProjectCardConstructor, начинаются тут */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .blockHeadingTitle {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0rem 0rem 0rem 0rem;
    margin-bottom: 40px;

    &__heading {
      color: var(--black-1D2E43);
      font-family: var(--font-unbounded);
      font-size: 24px;
      font-style: normal;
      font-weight: var(--fw-300);
      line-height: 32px;
      word-break: break-word;
    }

    &__text {
      color: var(--black-1D2E43);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 24px;
      word-break: break-word;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  // <<<< =========== стили для блока у которого 2 горизонтальные картинки и 1 вертикальная картинка imageBlockThree =========== >>>>

  .gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    /* Здесь используем fr для автоматического распределения пространства */
    gap: 30px;
    height: 100%;
    max-height: 48rem;
    align-items: stretch;
    margin-bottom: 70px;
  }

  .horizontalImage,
  .verticalImage {
    width: 100%;
    /* Для корректного отображения изображений внутри блока */
    object-fit: cover;
    /* Изображение будет подгоняться так, чтобы заполнить свой блок без искажений */
    display: block;
    /* Убираем стандартные отступы для изображений */
  }

  .horizontalImage {
    grid-column: 1 / 2;
    max-height: 24rem;
    border-radius: 1rem;
    /* 50% высоты экрана */
  }

  .horizontalImage:nth-child(1) {
    grid-row: 1 / 2;
  }

  .horizontalImage:nth-child(2) {
    grid-row: 2 / 3;
  }

  .verticalImage {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    // height: 100vh;
    border-radius: 1rem;
    /* 100% высоты экрана */
  }

  .quote_block_constainer {
    position: relative;
    margin-bottom: 40px;
    word-wrap: break-word;
    padding-right: 0;
    padding-left: 0;

    &__text_block {
      display: flex;
      width: 100%;
      padding: 24px;
      margin-right: 3rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 16px;
      background-color: var(--light-light-gray-EBECEE);
    }

    &__quote_text {
      color: var(--black-1D2E43);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 24px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    &__quote_author {
      color: var(--gray-959DA6);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 14px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 20px;
    }

    &__author_block {
      display: none;
    }

    &__img {
      display: none;
    }

    &__quote {
      display: none;
    }

    &__author {
      display: none;
    }
  }
  .containerImageToDescription {
    padding-left: 0;
    padding-right: 0;
  }

  .text_framed {
    padding-right: 0;
    padding-left: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .note_block {
    padding-right: 0;
    padding-left: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .text_block {
    padding-right: 0;
    padding-left: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .imageBlockTwo {
    padding-left: 0;
  }

  .slider_container {
    padding-left: 0;
  }

  .div_conteiner__blockSix {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    margin-bottom: 2.5rem;
  }

  .videoLinkContainer {
    padding-left: 0;
    margin-bottom: 2.5rem;
  }

  .buttonWrapperBlock1 {
    position: absolute;
    bottom: 20%;
    right: 3%;
  }

  .buttonFullscreen {
    width: 2rem;
    height: 2rem;
  }

  .buttonExitFullscreen {
    width: 2rem;
    height: 2rem;
  }
}

/* Стили для мобильной версии сайта блока ProjectCardConstructor, начинаются тут */
@media screen and (max-width: 768px) {
  .containerImageToDescription {
    padding: 0;
    margin: 2rem 0;
  }

  .blockHeadingTitle {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0;
    margin: 2rem 0;

    &__heading {
      color: var(--black-1D2E43);
      font-family: var(--font-unbounded);
      font-size: 24px;
      font-style: normal;
      font-weight: var(--fw-300);
      line-height: 32px;
      word-wrap: break-word;
      white-space: normal;
    }

    &__text {
      color: var(--black-1D2E43);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 24px;
      word-wrap: break-word;
      white-space: normal;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  // <<<< =========== стили для блока у которого 2 горизонтальные картинки и 1 вертикальная картинка imageBlockThree =========== >>>>

  .gridContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: 100%;
    height: 100%;
    // min-height: 60vh;
    // overflow: hidden;
    margin-bottom: 2.5rem;
    padding-left: 0;
    // padding-right: 1rem;
  }

  .horizontalImage,
  .verticalImage {
    width: 100%;
    object-fit: cover;
    display: block;
    height: auto;
    border-radius: 1rem;
    object-position: center;
  }

  .horizontalImage {
    grid-column: 1 / 2;
    height: 20vh;
    border-radius: 1rem;
    /* 50% высоты экрана */
  }

  .horizontalImage:nth-child(1) {
    grid-row: 1 / 2;
  }

  .horizontalImage:nth-child(2) {
    grid-row: 2 / 3;
  }

  .verticalImage {
    grid-column: 1 / 2;
    height: 20vh;
    border-radius: 1rem;
  }

  // <<< ====== стили для блока с двумя вертикальными картинками ====== >>>
  .imageBlockTwo {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 2.5rem;
    padding-left: 0;
    // padding-right: 1rem;
    // padding-top: 10rem;

    &__gridImage {
      width: 100%;
      min-height: 20vh;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 16px;
    }
  }

  // <<< ====== стили для блока заметкой  ====== >>>

  .quote_block_constainer {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
    word-wrap: break-word;
    padding-left: 0;

    &__text_block {
      display: flex;
      padding: 24px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 16px;
      background-color: var(--light-light-gray-EBECEE);
      word-wrap: break-word;
    }

    &__quote_text {
      color: var(--black-1D2E43);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 24px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    &__quote_author {
      color: var(--gray-959DA6);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 1.25rem;
    }

    &__author_block {
      display: none;
    }

    &__quote {
      display: none;
    }

    &__author {
      display: none;
    }
  }
  .containerImageToDescription {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .text_framed {
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-left: 0;
    padding-right: 0;
    white-space: pre-wrap;
    word-break: break-word;

    &__text {
      padding: 1.5rem 1rem;
    }
  }

  .note_block {
    display: flex;
    gap: 1rem;
    word-wrap: break-word;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin: 2rem 0;

    &__bookmark {
      width: 9px;
      height: auto;
      flex-shrink: 0;
      background: var(--salmon-FF7F6A);
    }

    &__text {
      padding: 2px 0px;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  .text_block {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin: 2rem 0;
    white-space: pre-wrap;
    word-break: break-word;
  }

  // <<< ====== стили для слайдера с картинками =) ====== >>>

  .slider_container {
    padding: 0;
    padding-left: 0rem;
    padding-right: 0rem;
    width: 100%;
    margin: 2rem 0;
  }

  .custom_slider {
    padding-right: 0px;
  }
  .div_conteiner__blockSix {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 2rem 0;
  }

  .videoLinkContainer {
    padding-left: 0;
    padding-bottom: 0px;
    margin: 2rem 0;
  }

  .buttonWrapperBlock1 {
    position: absolute;
    bottom: 25%;
    right: 3%;
  }

  .buttonFullscreen {
    width: 2rem;
    height: 2rem;
  }

  .buttonExitFullscreen {
    width: 2rem;
    height: 2rem;
  }
}
