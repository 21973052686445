.snowfall_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  overflow: hidden;
}

@keyframes fall {
  0% {
    transform: translateY(-10px) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}

.snowflake {
  position: absolute;
  top: -10px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  animation: fall linear infinite;
  /* Бесконечная анимация */
  opacity: 0.8;
}

/* Случайные стили для разнообразия снежинок */
.snowflake:nth-child(odd) {
  width: 5px;
  height: 5px;
  animation-duration: 8s;
}

.snowflake:nth-child(even) {
  width: 7px;
  height: 7px;
  animation-duration: 12s;
}

.snowflake:nth-child(3n) {
  animation-duration: 10s;
  animation-delay: 2s;
}